/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLogoutMutation } from '../features/auth/authApiSlice';
import SpinerComponent from '../components/SpinerComponent';
import { toast } from 'react-toastify';
import './Navbar.css';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from '../features/users/customersApiSlice';
import { AiOutlineClose } from 'react-icons/ai';

export const Navbar = () => {
  const [Logout, { isLoading, isSuccess, error }] = useLogoutMutation();
  const navigate = useNavigate();
  const [showSpina, setShowSpina] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const cid = localStorage.getItem('cid');

  const { user } = useGetUsersQuery('usersList', {
    selectFromResult: ({ data }) => ({
      user: data?.entities[cid],
    }),
  });

  const gotoOrders = () => {
    navigate('/user-orders');
  };

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }
    if (isSuccess) {
      setShowSpina(false);
      navigate('/');
    }

    if (error) {
      setShowSpina(false);
      toast.error('Something went wrong!');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, error]);

  let collapsedNav;

  collapsedNav = (
    <>
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        {!menuOpen && (
          <>
            <span></span>
            <span></span>
            <span></span>
          </>
        )}

        {menuOpen && (
          <div style={{ marginRight: '4rem' }}>
            <AiOutlineClose size={50} color="black" />
          </div>
        )}
      </div>

      <div>
        <ul className={menuOpen ? 'open' : ''}>
          <li>
            {user && (
              <Link className="linky">
                <p
                  style={{ fontSize: '20px', marginTop: '1rem' }}
                  className="text-black  dropdown-toggle text-wrap"
                  id="dropdownMenuP"
                  data-bs-toggle="dropdown"
                >
                  {user?.firstname}
                </p>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuP">
                  <li>
                    <Link
                      className="dropdown-item text-black"
                      to="/user-account-page"
                    >
                      Profile
                    </Link>
                  </li>
                </ul>
              </Link>
            )}
            {!user && (
              <NavLink
                className="menu-cover"
                onClick={() => {
                  setMenuOpen(false);
                }}
                to={'/login'}
              >
                Login
              </NavLink>
            )}

            {user && (
              <NavLink
                className="dontshow"
                onClick={() => {
                  setMenuOpen(false);
                  navigate('/');
                }}
                to={'/'}
              >
                Home
              </NavLink>
            )}

            {user && (
              <NavLink
                to={'/user-orders'}
                className="dontshow"
                onClick={() => {
                  setMenuOpen(false);
                }}
              >
                Orders
              </NavLink>
            )}
            {user && (
              <NavLink
                to={'/user-account-page'}
                className="dontshow"
                onClick={() => {
                  setMenuOpen(false);
                }}
              >
                Profile
              </NavLink>
            )}

            {user && (
              <NavLink
                className={'menu-cover'}
                onClick={() => {
                  Logout();
                  setMenuOpen(false);
                }}
              >
                Logout
              </NavLink>
            )}
          </li>
        </ul>
      </div>
    </>
  );

  return (
    <nav>
      <Link
        to="/"
        className="title"
        // style={{ height: '1.8em', width: '8.5em' }}
      >
        <img
          src="/images/ppat.png"
          style={{
            objectFit: 'fill',
            height: '100%',
            width: '100%',
          }}
          alt="brand"
        />
      </Link>

      {collapsedNav}
      {showSpina && <SpinerComponent />}
    </nav>
  );
};
