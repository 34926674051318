import React from 'react';

const ThreeMonthsPlanDisplay = ({ productPrice }) => {
  return (
    <div className="d-flex flex-column gap-1">
      <div className="product-card">
        <label className="price-label">3 months</label>
        <div>
          <p className="price-pln">
            Initial payment: GHS {Math.round(productPrice * 0.4)}
          </p>
          <p className="price-pln">
            Next payment: GHS {Math.round(productPrice * 0.3)}
          </p>
          <p className="price-pln">
            Next payment: GHS {Math.round(productPrice * 0.3)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThreeMonthsPlanDisplay;
