/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useGetProductsQuery } from '../features/products/productsApiSlice';
import ProductListComponent from '../components/ProductListComponent';
import Meta from '../components/Meta';
import BreadCrumb from '../components/BreadCrumb';

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  let phoneandtabs;
  let electronicapp;
  let foodmarket;
  let homeandoffice;
  let fashionandwears;
  let compandaccess;
  let beautyandcosmetics;

  let parray = [];
  let parray2 = [];
  let parray3 = [];
  let parray4 = [];
  let parray5 = [];
  let parray6 = [];
  let parray7 = [];

  const { data, isSuccess } = useGetProductsQuery('productsList', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  if (isSuccess) {
    const { ids, entities } = data;

    parray = ids.filter((idxx) => {
      return entities[idxx]?.category === 'Phones and Tablets';
    });
    parray2 = ids.filter((idxx) => {
      return entities[idxx]?.category === 'Electronic Appliances';
    });
    parray3 = ids.filter((idxx) => {
      return entities[idxx]?.category === 'Food Market';
    });
    parray4 = ids.filter((idxx) => {
      return entities[idxx]?.category === 'Home and Office';
    });
    parray5 = ids.filter((idxx) => {
      return entities[idxx]?.category === 'Fashion and wears';
    });
    parray6 = ids.filter((idxx) => {
      return entities[idxx]?.category === 'Computers and accessories';
    });
    parray7 = ids.filter((idxx) => {
      return entities[idxx]?.category === 'Beauty and Cosmetics';
    });

    phoneandtabs = (
      <ProductListComponent
        parray={parray}
        cat={'Phones and Tablets'}
        ent={entities}
      />
    );
    electronicapp = (
      <ProductListComponent
        parray={parray2}
        cat={'Electronic Appliances'}
        ent={entities}
      />
    );
    foodmarket = (
      <ProductListComponent
        parray={parray3}
        cat={'Food Market'}
        ent={entities}
      />
    );
    homeandoffice = (
      <ProductListComponent
        parray={parray4}
        cat={'Home and Office'}
        ent={entities}
      />
    );
    fashionandwears = (
      <ProductListComponent
        parray={parray5}
        cat={'Fashion and wears'}
        ent={entities}
      />
    );
    compandaccess = (
      <ProductListComponent
        parray={parray6}
        cat={'Computers and accessories'}
        ent={entities}
      />
    );
    beautyandcosmetics = (
      <ProductListComponent
        parray={parray6}
        cat={'Beauty and Cosmetics'}
        ent={entities}
      />
    );
  }

  return (
    <>
      <Meta title="Home" />
      <BreadCrumb title="Home" />
      <section className="home-wrapper ">
        <div className="container-fluid py-1 mx-1">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div
                id="carouselExampleCaptions"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src="/images/carouselOne.png"
                      className="d-block w-100 image-cara"
                      alt="carousel"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/images/carouselTwo.png"
                      className="d-block w-100 image-cara"
                      alt="carousel"
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {parray.length > 0 && phoneandtabs}
      {parray2.length > 0 && electronicapp}
      {parray3.length > 0 && foodmarket}
      {parray4.length > 0 && homeandoffice}
      {parray5.length > 0 && fashionandwears}
      {parray6.length > 0 && compandaccess}
      {parray7.length > 0 && beautyandcosmetics}
    </>
  );
};

export default Home;
