import React from 'react';
import CustomInputy from '../components/CustomInputTwo';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SpinerComponent from '../components/SpinerComponent';
import { setCredentials } from '../features/auth/authSlice';
import Meta from '../components/Meta';
import BreadCrumb from '../components/BreadCrumb';
import { useAddNewUserMutation } from '../features/users/customersApiSlice';
import { toast } from 'react-toastify';

let schema = Yup.object().shape({
  email: Yup.string()
    .email('email should be valid')
    .required('email is required'),
  password: Yup.string().required('password is required'),
  confirm_password: Yup.string()
    .label('confirm password')
    .required('please confirm password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const SignupOne = () => {
  const [addNewUser, { data, isLoading, isSuccess, error }] =
    useAddNewUserMutation();

  const [showSpina, setShowSpina] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }
    if (isSuccess) {
      setShowSpina(false);
      const { accessToken } = data;
      dispatch(setCredentials({ accessToken }));
      navigate(`/signup-page-two/${data?.newUser?._id}`);
    }
    if (error) {
      setShowSpina(false);
      setShowErr(true);
      toast.error('Something went wrong!');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, error]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const { email, password } = values;

      try {
        await addNewUser({ email, password });
      } catch (error) {
        throw new Error(error);
      }
    },
  });

  return (
    <>
      <Meta title="Signup page" />
      <BreadCrumb title="Signup page" />

      <section className="home-wrapper p-0 m-0">
        <div className="container-fluid py-1 ">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <form
                id="signoneform"
                className="d-flex flex-column align-items-center gap-2"
                action=""
                onSubmit={formik.handleSubmit}
              >
                <h1>Sign up</h1>
                <CustomInputy
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  id="email"
                  val={formik.values.email}
                  onCh={formik.handleChange('email')}
                />
                <div className="error">
                  {formik.touched.email && formik.errors.email ? (
                    <div>{formik.errors.email}</div>
                  ) : null}
                </div>
                <CustomInputy
                  type="password"
                  name="password"
                  placeholder="Password"
                  id="pass"
                  val={formik.values.password}
                  onCh={formik.handleChange('password')}
                />
                <div className="error">
                  {formik.touched.password && formik.errors.password ? (
                    <div>{formik.errors.password}</div>
                  ) : null}
                </div>
                <CustomInputy
                  type="password"
                  name="confirm_password"
                  placeholder="confirm Password"
                  id="confirm_pass"
                  val={formik.values.confirm_password}
                  onCh={formik.handleChange('confirm_password')}
                />
                <div className="error">
                  {formik.touched.confirm_password &&
                  formik.errors.confirm_password ? (
                    <div>{formik.errors.confirm_password}</div>
                  ) : null}
                </div>

                <button
                  className="signupbtn border-0 mb-3 px-3 py-2 text-white fw-bold text-center text-decoration-none fs-5"
                  type="submit"
                >
                  next
                </button>
                {showSpina && <SpinerComponent />}
                {showErr && (
                  <p style={{ color: 'red' }}>Something went wrong!</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignupOne;
