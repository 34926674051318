/* eslint-disable array-callback-return */
import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';
import ProductCard from '../components/ProductCard';

const ProductListComponent = ({ ent, cat, parray }) => {
  return (
    <>
      <section className="home-wrapper pb-5">
        <div className="container-fluid pt-2 pe-3 ps-4">
          <div className="row home-wrapper-divv">
            <div className="col-12 col-sm-12 col-md-12 section-header pt-2   d-flex justify-content-between">
              <h6 className="text-white">{cat}</h6>
              <Link
                to={`/see-all-products/?category=${cat}`}
                className="d-flex align-items-center"
                style={{ textDecoration: 'none' }}
              >
                <p className="text-white mt-3">See all</p>
                <IoIosArrowForward className="text-white fs-4" />
              </Link>
            </div>
            <div className="container-fluid mb-5">
              <div className="row">
                {parray.map((idx, ind) => {
                  if (ent[idx]?.category === cat && ind < 4) {
                    return (
                      <div className="col-3  gap-10  mb-5">
                        <ProductCard prd={ent[idx]} />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductListComponent;
