import React, { useEffect, useState } from 'react';
import Meta from '../components/Meta';
import BreadCrumb from '../components/BreadCrumb';
import { useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomInputy from '../components/CustomInputTwo';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useLoginMutation } from '../features/auth/authApiSlice';
import { setCredentials } from '../features/auth/authSlice';
import SpinerComponent from '../components/SpinerComponent';

const loginSchema = yup.object({
  email: yup
    .string()
    .email('Email should be valid')
    .required('Email is required!'),

  password: yup.string().required('Password is required!'),
});

const Login = () => {
  // eslint-disable-next-line no-unused-vars
  const [showLoginError, setShowLoginError] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [showSpina, setShowSpina] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, { data, isLoading, isSuccess, error }] = useLoginMutation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }
    if (isSuccess) {
      toast.success('Login Success!');
      setShowSpina(false);
      localStorage.setItem('cmail', data?.email);
      localStorage.setItem('cid', data?.id);
      navigate(-1);
    }

    if (error) {
      setShowSpina(false);
      setShowLoginError(true);
      setLoginError(error.data.msg);
      toast.error('Something went wrong!');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isLoading, error]);

  const formik = useFormik({
    initialValues: {
      email: '',

      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      //dispatch(loginUser(values));
      const { email, password } = values;

      try {
        const data = await login({ email, password }).unwrap();
        dispatch(setCredentials(data));
      } catch (error) {
        throw new Error(error);
      }
    },
  });
  return (
    <>
      <Meta title="Login page" />
      <BreadCrumb title="Login page" />
      <section className=" home-wrapper p-0 m-0">
        <div className="container-fluid py-1">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <h3 className="text-center mb-3">Login</h3>
              <form
                onSubmit={formik.handleSubmit}
                className="d-flex align-items-center flex-column gap-15"
              >
                <div>
                  <CustomInputy
                    type="email"
                    name="email"
                    placeholder="email"
                    val={formik.values.email}
                    onCh={formik.handleChange('email')}
                    onBl={formik.handleBlur('email')}
                  />
                  <div className="error">
                    {formik.touched.email && formik.errors.email}
                  </div>
                </div>
                <div>
                  <CustomInputy
                    type="password"
                    name="password"
                    placeholder="password"
                    val={formik.values.password}
                    onCh={formik.handleChange('password')}
                    onBl={formik.handleBlur('password')}
                  />
                  <div className="error">
                    {formik.touched.password && formik.errors.password}
                  </div>
                </div>
                <div>
                  <Link
                    style={{ textDecoration: 'none', fontSize: '0.8rem' }}
                    className="text-black"
                    to="/forgot-password"
                  >
                    forgot password?
                  </Link>
                </div>
                <div className="d-flex justify-content-center align-items-center ">
                  <button className="buttonext" type="submit">
                    Login
                  </button>
                </div>
                {showLoginError && (
                  <div>
                    <p style={{ color: 'red' }}>
                      {loginError
                        ? loginError
                        : ' Invalid Email and password details.'}
                    </p>
                  </div>
                )}

                <div className="d-flex flex-column mb-3">
                  <Link
                    style={{
                      textDecoration: 'none',
                      fontSize: '1.2rem',
                    }}
                    className="text-red"
                    to="/signup-one"
                  >
                    New User? Signup
                  </Link>
                </div>

                {showSpina && <SpinerComponent />}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
