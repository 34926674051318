/* eslint-disable no-unused-vars */
import React from 'react';
import BreadCrumb from '../components/BreadCrumb';
import CustomInputy from '../components/CustomInputTwo';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Meta from '../components/Meta';
import { Select } from 'antd';
import SpinerComponent from '../components/SpinerComponent';
import { useGetUsersQuery } from '../features/users/customersApiSlice';
import { useUpdateUserMutation } from '../features/users/customersApiSlice';
import { toast } from 'react-toastify';

let schema = Yup.object().shape({
  firstname: Yup.string().required('First Name is required'),
  lastname: Yup.string().required('Last Name is required'),
  company: Yup.string().required('company is required'),
  department: Yup.string().required('department is required'),
  employmentCategory: Yup.string().required('Employment Category is required'),
});

const SignupPageTwo = () => {
  const navigate = useNavigate();
  const [showSpina, setShowSpina] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [firstnamee, setFirstname] = useState(null);

  const { id } = useParams();

  const { user } = useGetUsersQuery('usersList', {
    selectFromResult: ({ data }) => ({
      user: data?.entities[id],
    }),
  });

  const [updateUser, { isLoading, isSuccess, error }] = useUpdateUserMutation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }

    if (isSuccess) {
      sendEmailToVendor();
      setShowSpina(false);
      localStorage.setItem('cmail', user?.email);
      navigate(`/signup-page-three/${user?._id}`);
    }
    if (error) {
      setShowSpina(false);
      setShowErr(true);
      toast.error('Something went wrong!');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, error]);

  const sendEmailToVendor = async () => {
    try {
      const email = user?.email;
      const firstname = firstnamee;
      const formData = new FormData();
      formData.append('firstname', firstname);
      formData.append('email', email);
      const urlEncoded = new URLSearchParams(formData).toString();
      await fetch(
        'https://worknshup-main-backend-a9a88924fa9a.herokuapp.com/api/user/send-email',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
          },
          body: urlEncoded,
        }
      ).catch((err) => console.log(`Error occured: ${err}`));
    } catch (err) {
      console.log('error: email not sent!');
    }
  };

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      company: '',
      department: '',
      employmentCategory: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const { firstname, lastname, company, department, employmentCategory } =
        values;

      const id = user?._id;

      try {
        await updateUser({
          id,
          firstname,
          lastname,
          company,
          department,
          employmentCategory,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  });

  return (
    <>
      <Meta title="Sign-Up-Two" />
      <BreadCrumb title="Signup page Two" />

      <section className="home-wrapper p-0 m-0">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <form
                id="signtwoform"
                className="d-flex flex-column align-items-center gap-2 "
                action=""
                onSubmit={formik.handleSubmit}
              >
                <h2 className="pb-1">Employment details</h2>

                <CustomInputy
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder={'First Name'}
                  val={formik.values.firstname}
                  onCh={(e) => {
                    setFirstname(e.target.value);
                    formik.handleChange(e);
                  }}
                />
                <div className="error">
                  {formik.touched.firstname && formik.errors.firstname ? (
                    <div>{formik.errors.firstname}</div>
                  ) : null}
                </div>
                <CustomInputy
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder={'Last Name'}
                  val={formik.values.lastname}
                  onCh={formik.handleChange('lastname')}
                />
                <div className="error">
                  {formik.touched.lastname && formik.errors.lastname ? (
                    <div>{formik.errors.lastname}</div>
                  ) : null}
                </div>

                <Select
                  className="select-cat"
                  showSearch
                  placeholder="Select Your Company"
                  optionFilterProp="children"
                  onChange={formik.handleChange('company')}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'DEUNITED FOODS LIMITED',
                      label: 'DEUNITED FOODS LIMITED',
                    },
                    {
                      value: 'COCACOLA COMPANY LIMITED',
                      label: 'COCACOLA COMPANY LIMITED',
                    },
                    {
                      value: 'NESTLE FOODS LIMITED',
                      label: 'NESTLE FOODS LIMITED',
                    },
                    {
                      value: 'GUINESS COMPANY LIMITED',
                      label: 'GUINESS COMPANY LIMITED',
                    },
                    {
                      value: 'ROYAL CROWN',
                      label: 'ROYAL CROWN',
                    },
                    {
                      value: 'EQUATOR BUISCUITS',
                      label: 'EQUATOR BUISCUITS',
                    },
                    {
                      value: 'KASAPREKO',
                      label: 'KASAPREKO',
                    },
                  ]}
                />
                <div className="error">
                  {formik.touched.company && formik.errors.company}
                </div>

                <Select
                  className="select-cat"
                  showSearch
                  placeholder="Your Department"
                  optionFilterProp="children"
                  onChange={formik.handleChange('department')}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'ADMINISTRATION',
                      label: 'ADMINISTRATION',
                    },
                    {
                      value: 'ENGINEERING',
                      label: 'ENGINEERING',
                    },
                    {
                      value: 'PRODUCTION',
                      label: 'PRODUCTION',
                    },
                    {
                      value: 'QUALITY ASSURANCE',
                      label: 'QUALITY ASSURANCE',
                    },
                    {
                      value: 'WAREHOUSE',
                      label: 'WAREHOUSE',
                    },
                    {
                      value: 'PURCHASING',
                      label: 'PURCHASING',
                    },
                    {
                      value: 'I.T',
                      label: 'I.T',
                    },
                    {
                      value: 'OPERATIONS',
                      label: 'OPERATIONS',
                    },
                    {
                      value: 'SALES AND MARKETING',
                      label: 'SALES AND MARKETING',
                    },
                    {
                      value: 'FINANCE AND ADMINISTRATION',
                      label: 'FINANCE AND ADMINISTRATION',
                    },
                    {
                      value: 'FIELD OPERATIONS',
                      label: 'FIELD OPERATIONS',
                    },
                    {
                      value: 'OTHERS',
                      label: 'OTHERS',
                    },
                  ]}
                />
                <div className="error">
                  {formik.touched.department && formik.errors.department ? (
                    <div>{formik.errors.department}</div>
                  ) : null}
                </div>

                <Select
                  className="select-cat"
                  showSearch
                  placeholder="Employment Category"
                  optionFilterProp="children"
                  onChange={formik.handleChange('employmentCategory')}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'PERMANENT STAFF',
                      label: 'PERMANENT STAFF',
                    },
                    {
                      value: 'CONTRACT STAFF',
                      label: 'CONTRACT STAFF',
                    },
                    {
                      value: 'CASUAL STAFF',
                      label: 'CASUAL STAFF',
                    },
                  ]}
                />
                <div className="error">
                  {formik.touched.employmentCategory &&
                  formik.errors.employmentCategory ? (
                    <div>{formik.errors.employmentCategory}</div>
                  ) : null}
                </div>

                <button
                  className="signupbtn border-0 px-3 py-2 text-white fw-bold text-center  mt-5 fs-5"
                  type="submit"
                >
                  next
                </button>
                {showSpina && <SpinerComponent />}
                {showErr && (
                  <p style={{ color: 'red' }}>Something went wrong!</p>
                )}
              </form>
            </div>
          </div>
          <br />
        </div>
      </section>
    </>
  );
};

export default SignupPageTwo;
