/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useGetUserOrdersListQuery } from '../features/users/customersApiSlice';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate'; // for pagination
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai'; // icons form react-icons
import { IconContext } from 'react-icons'; // for customizing icons

const UserOrders = () => {
  // const [page, setPage] = useState(0);
  // const [filterData, setFilterData] = useState();
  // const n = 10;

  const cid = localStorage.getItem('cid');
  const userr = JSON.parse(localStorage.getItem('customer'));
  const { data: orders = [], isSuccess } = useGetUserOrdersListQuery(
    'ordersList',
    {
      pollingInterval: 15000,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  // useEffect(() => {
  //   setFilterData(
  //     data?.userOrder?.map((item, index) => {
  //       return (index >= page * n) & (index < (page + 1) * n);
  //     })
  //   );
  // }, [page, isSuccess]);

  let content = (
    <div>
      <div className="contact-inner-wrapper d-flex justify-content-between">
        <div>
          <h3 className="contact-title mb-4">Orders</h3>
        </div>
      </div>
    </div>
  );

  try {
    if (isSuccess) {
      // const { ids, entities } = data;
      // const { ids, entities } = orders;
      const userOdas = orders?.filter((ide) => {
        return ide?.user?._id === cid;
      });

      content = (
        <div className="container-fluid cont-wrapper d-flex flex-column align-items-center">
          <div>
            <h3 className="contact-title mb-4">Orders</h3>
          </div>
          <div className="row w-100">
            <div className="col col-sm col-md">order no</div>
            <div className="col col-sm col-md">item name</div>
            <div className="col col-sm col-md">status</div>
            <div className="col col-sm col-md">date</div>
          </div>
          {userOdas?.map((idy) => {
            return (
              <Link
                className="w-100"
                to={`/user-order-details/${idy?._id}`}
                style={{
                  borderRadius: '0.5rem',
                  textDecoration: 'none',
                  backgroundColor: 'white',
                  marginBottom: '0.5rem',
                  padding: '0.5rem 0.5rem 0.5rem 0.5rem',
                }}
              >
                <div key={idy} className="row w-100">
                  <div className="col col-sm col-md">{idy?.orderNumber}</div>
                  <div className="col col-sm col-md">{idy?.product?.title}</div>

                  <div className="col col-sm col-md">{idy?.orderStatus}</div>
                  <div className="col col-sm col-md">{idy?.orderDate}</div>
                </div>
              </Link>
            );
          })}
        </div>
      );
    }
  } catch (err) {
    content = <p>{err}</p>;
  }

  return content;
};

export default UserOrders;
