/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from '../features/users/customersApiSlice';
import Meta from '../components/Meta';

const UserAccountDetails = () => {
  const navigate = useNavigate();
  const cid = localStorage.getItem('cid');
  const userr = JSON.parse(localStorage.getItem('customer'));
  const { user } = useGetUsersQuery('usersList', {
    selectFromResult: ({ data }) => ({
      user: data?.entities[cid],
    }),
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    //eslint-disable-next-line react-hooks/exhaustive-deps
    if (userr === null) {
      navigate('/login');
    }
  }, []);

  return (
    <>
      <Meta title="User Profile" />
      <div className="container-fluid d-flex flex-column align-items-center">
        <div className="border-bottom w-100">
          <h3 className="contact-title mb-4">Account Overview</h3>
        </div>
        <div className="row my-2">
          <div className="col-4 col-sm-4 col-md-4 my-2 mx-4 acc-det1">
            <h6>FullName:</h6>
            <p className="">
              {user?.firstname} {user?.lastname}
            </p>
          </div>
          <div className="col-4 col-sm-4 col-md-4 my-2 mx-4 acc-det1">
            <h6>Store Credit:</h6>
            <p style={{ fontSize: '3rem' }} className="">
              GHS{user?.storeCredit}
            </p>
          </div>
          <div className="col-4 col-sm-4 col-md-4 my-2 mx-4 acc-det1">
            <h6>Email:</h6>
            <p className="">{user?.email}</p>
          </div>
        </div>
        <div className="row my-2 ">
          <div className="col-4 col-sm-4 col-md-4 my-2 mx-4 acc-det1">
            <h6>Phone Number:</h6>
            <p className="">{user?.mobile}</p>
          </div>
          <div className="col-4 col-sm-4 col-md-4 my-2 mx-4 acc-det1">
            <h6>Company:</h6>
            <p className="">{user?.company}</p>
          </div>
          <div className="col-4 col-sm-4 col-md-4 my-2 mx-4 acc-det1">
            <h6>Orders:</h6>
            <Link style={{ textDecoration: 'none' }} to={`/user-orders`}>
              see all orders
            </Link>
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Link to={`/edit-user/${user?._id}`}>edit</Link>
        </div>
      </div>
    </>
  );
};

export default UserAccountDetails;
