import React from 'react';
import CustomInputy from '../components/CustomInputTwo';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Meta from '../components/Meta';
import { Select } from 'antd';
import SpinerComponent from '../components/SpinerComponent';
import { useGetUsersQuery } from '../features/users/customersApiSlice';
import { useUpdateUserMutation } from '../features/users/customersApiSlice';
import BreadCrumb from '../components/BreadCrumb';
import { toast } from 'react-toastify';

let schema = Yup.object().shape({
  yearsOfEmployment: Yup.string().required('Years of Employment is required'),
  headOfDepartment: Yup.string().required('Head of Department is required'),
  salaryRange: Yup.string().required('Salary Range is required'),
  mobile: Yup.string().required('Mobile number is required'),
  address: Yup.string().required('Address is required'),
});

const SignupPageThree = () => {
  const navigate = useNavigate();
  const [showSpina, setShowSpina] = useState(false);
  const [showErr, setShowErr] = useState(false);
  let registrationCompleted = true;
  const { id } = useParams();
  localStorage.setItem('cid', id);

  const { user } = useGetUsersQuery('usersList', {
    selectFromResult: ({ data }) => ({
      user: data?.entities[id],
    }),
  });

  const [updateUser, { isLoading, isSuccess, error }] = useUpdateUserMutation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  // const sendEmailToVendor = async () => {
  //   try {
  //     const BusinessName = vendor?.BusinessName;
  //     const email = vendor?.email;
  //     const formData = new FormData();
  //     formData.append('BusinessName', BusinessName);
  //     formData.append('email', email);
  //     const urlEncoded = new URLSearchParams(formData).toString();
  //     await fetch(
  //       'https://worknshup-vendor-backend-0ea3e2e25f6c.herokuapp.com/api/vendor/send-email',
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-type': 'application/x-www-form-urlencoded',
  //         },
  //         body: urlEncoded,
  //       }
  //     ).catch((err) => console.log(`Error occured: ${err}`));
  //   } catch (err) {
  //     console.log('error: email not sent!');
  //   }
  // };

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }

    if (isSuccess) {
      toast.success('Account Created Successfully!');
      setShowSpina(false);
      navigate(`/`);
    }
    if (error) {
      setShowSpina(false);
      setShowErr(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, error]);

  const formik = useFormik({
    initialValues: {
      yearsOfEmployment: '',
      headOfDepartment: '',
      salaryRange: '',
      mobile: '',
      address: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const {
        yearsOfEmployment,
        headOfDepartment,
        salaryRange,
        mobile,
        address,
      } = values;

      const id = user._id;

      try {
        await updateUser({
          id,
          yearsOfEmployment,
          headOfDepartment,
          salaryRange,
          mobile,
          address,
          registrationCompleted,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  });

  return (
    <>
      <Meta title="Sign-Up-Three" />
      <BreadCrumb title="Signup-page-Three" />
      <section className="home-wrapper p-0 m-0">
        <div className="container-fluid py-1 ">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <form
                id="signthreeform"
                className="d-flex flex-column align-items-center gap-10 pb-5"
                action=""
                onSubmit={formik.handleSubmit}
              >
                <h2 className="pb-5">Employment details</h2>
                <label htmlFor="yearsOfEmployment">Years Of Employment</label>
                <Select
                  className="select-cat"
                  showSearch
                  placeholder="Years Of Employment"
                  optionFilterProp="children"
                  onChange={formik.handleChange('yearsOfEmployment')}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'LESS THAN TWO YEARS',
                      label: 'LESS THAN TWO YEARS',
                    },
                    {
                      value: 'BETWEEN TWO AND FIVE YEARS',
                      label: 'BETWEEN TWO AND FIVE YEARS',
                    },
                    {
                      value: 'MORE THAN FIVE YEARS',
                      label: 'MORE THAN FIVE YEARS',
                    },
                  ]}
                />
                <div className="error">
                  {formik.touched.yearsOfEmployment &&
                  formik.errors.yearsOfEmployment ? (
                    <div>{formik.errors.yearsOfEmployment}</div>
                  ) : null}
                </div>

                <label htmlFor="salaryRange">Salary Range</label>
                <Select
                  className="select-cat"
                  showSearch
                  placeholder="Salary Range"
                  optionFilterProp="children"
                  onChange={formik.handleChange('salaryRange')}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'LESS THAN 1000 CEDIS',
                      label: 'LESS THAN 1000 CEDIS',
                    },
                    {
                      value: 'BETWEEN 1000 AND 2000 CEDIS',
                      label: 'BETWEEN 1000 AND 2000 CEDIS',
                    },
                    {
                      value: 'BETWEEN 2000 AND 5000 CEDIS',
                      label: 'BETWEEN 2000 AND 5000 CEDIS',
                    },

                    {
                      value: 'MORE THAN 5000 CEDIS',
                      label: 'MORE THAN 5000 CEDIS',
                    },
                  ]}
                />
                <div className="error">
                  {formik.touched.salaryRange && formik.errors.salaryRange ? (
                    <div>{formik.errors.salaryRange}</div>
                  ) : null}
                </div>

                <label htmlFor="headOfDepartment">Head Of Department</label>
                <CustomInputy
                  type="text"
                  name="headOfDepartment"
                  id="headOfDepartment"
                  val={formik.values.headOfDepartment}
                  onCh={formik.handleChange('headOfDepartment')}
                />
                <div className="error">
                  {formik.touched.headOfDepartment &&
                  formik.errors.headOfDepartment ? (
                    <div>{formik.errors.headOfDepartment}</div>
                  ) : null}
                </div>

                <CustomInputy
                  type="text"
                  name="mobile"
                  placeholder={'Your Mobile Number'}
                  id="mobile"
                  val={formik.values.mobile}
                  onCh={formik.handleChange('mobile')}
                />
                <div className="error">
                  {formik.touched.mobile && formik.errors.mobile ? (
                    <div>{formik.errors.mobile}</div>
                  ) : null}
                </div>
                <CustomInputy
                  type="text"
                  name="address"
                  placeholder={'Your Home Address'}
                  id="address"
                  val={formik.values.address}
                  onCh={formik.handleChange('address')}
                />
                <div className="error">
                  {formik.touched.address && formik.errors.address ? (
                    <div>{formik.errors.address}</div>
                  ) : null}
                </div>

                <button
                  className="signupbtn border-0 px-3 py-2 text-white fw-bold text-center  mt-5 fs-5"
                  type="submit"
                >
                  next
                </button>
                {showSpina && <SpinerComponent />}
                {showErr && (
                  <p style={{ color: 'red' }}>Something went wrong!</p>
                )}
              </form>
            </div>
          </div>
          <br />
        </div>
      </section>
    </>
  );
};

export default SignupPageThree;
