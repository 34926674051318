import React from 'react';

const TwoMonthsPlan = ({ productPrice, onCh }) => {
  return (
    <div className="d-flex border-bottom flex-column gap-1">
      <div className="product-card">
        <div className="d-flex">
          <input
            type="radio"
            name="payplan"
            value="2 MONTHS"
            id="2 MONTHS"
            // defaultChecked={setPaymentDuration('2 MONTHS')}
            onChange={onCh}
            className="me-1 mb-0"
          />
          <label className="price-label">2 months</label>
        </div>
        <div>
          <p className="price-pln">
            Initial payment: GHS {Math.round(productPrice * 0.55)}
          </p>
          <p className="price-pln">
            Next payment: GHS {Math.round(productPrice * 0.45)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TwoMonthsPlan;
