/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useGetUserOrdersListQuery } from '../features/users/customersApiSlice';
import { useLocation, useParams } from 'react-router-dom';

const OrderDetailPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { id } = useParams();
  // const que = searchParams.get('subid');
  // let ide = JSON.parse(id);

  let content = <p>Loading...</p>;

  const { data, isSuccess } = useGetUserOrdersListQuery('ordersList', {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  // const { order, isSuccess } = useGetUserOrdersListQuery('usersList', {
  //   selectFromResult: ({ data, isSuccess }) => ({
  //     order: data?.entities[id] ?? null,
  //     isSuccess,
  //   }),
  // });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  if (isSuccess) {
    const od = data?.find((it) => {
      return it?._id === id;
    });

    content = (
      <section className="container-fluid cont-wrapper">
        <div className="row">
          <div className="col-12 col-md-12 col-sm-12 d-flex flex-column align-items-center pt-5">
            <h3>Order details</h3>
            <ul className="gap-20 mt-5">
              <li>
                <p className="odadet">
                  Item Name: <span> {od?.product?.title} </span>
                </p>
              </li>
              <li>
                <p className="odadet">
                  Order Number: <span> {od?.orderNumber} </span>{' '}
                </p>
              </li>
              <li>
                <p className="odadet">
                  Price: <span>GHC {od?.totalPrice} </span>{' '}
                </p>
              </li>
              <li>
                <p className="odadet">
                  Status: <span> {od?.orderStatus} </span>{' '}
                </p>
              </li>
              <li>
                <p className="odadet">
                  Date: <span> {od?.orderDate} </span>{' '}
                </p>
              </li>
              <li>
                <p className="odadet">
                  Payment Plan: <span> {od?.paymentPlan} </span>{' '}
                </p>
              </li>
              <li>
                <p className="odadet">
                  Payment Duration: <span> {od?.paymentDuration} </span>{' '}
                </p>
              </li>
              {od?.initialPayment && (
                <li>
                  <p className="odadet">
                    Initial Payment: <span>GHS {od?.initialPayment} </span>{' '}
                  </p>
                </li>
              )}
              {od?.secondPayment && (
                <li>
                  <p className="odadet">
                    Second Payment: <span>GHS {od?.secondPayment} </span>{' '}
                  </p>
                </li>
              )}
              {od?.thirdPayment && (
                <li>
                  <p className="odadet">
                    Third Payment: <span>GHS {od?.thirdPayment} </span>{' '}
                  </p>
                </li>
              )}
              {od?.fourthPayment && (
                <li>
                  <p className="odadet">
                    Fourth Payment: <span>GHS {od?.fourthPayment} </span>{' '}
                  </p>
                </li>
              )}
              {od?.fifthPayment && (
                <li>
                  <p className="odadet">
                    Fifth Payment: <span>GHS {od?.fifthPayment} </span>{' '}
                  </p>
                </li>
              )}
              {od?.sixthPayment && (
                <li>
                  <p className="odadet">
                    Sixth Payment: <span>GHS {od?.sixthPayment} </span>{' '}
                  </p>
                </li>
              )}
            </ul>
          </div>
        </div>
      </section>
    );
  }

  // useEffect(() => {
  //   const oda = data?.userOrder?.orderItems[0]?.orderNumber;
  // }, [isSuccess]);

  return content;
};

export default OrderDetailPage;
