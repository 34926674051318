/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import OrderCradPopup from './OrderCradPopup';
import { useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from '../features/users/customersApiSlice';
import StoreCreditAlertPopup from './StoreCreditAlertPopup';
import TwoMonthsPlanDisplay from './TwoMonthsPlanDisplay';
import ThreeMonthsPlanDisplay from './ThreeMonthsPlanDisplay';

const OrderCradThree = ({ prdId, productPrice }) => {
  const [showpopup, setShowpopup] = useState(false);
  const [showCreditpopup, setShowCreditpopup] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('customer'));
  const userid = localStorage.getItem('cid');

  const { usa } = useGetUsersQuery('usersList', {
    selectFromResult: ({ data }) => ({
      usa: data?.entities[userid],
    }),
  });

  const showPup = () => {
    setShowpopup(!showpopup);
  };

  //const tPrice = product?.price + product?.deliveryPrice;

  const checkUser = () => {
    if (user === null) {
      navigate('/login');
    } else if (usa?.storeCredit < 500) {
      setShowCreditpopup(!showCreditpopup);
    } else {
      navigate(`/order-product-installment/${prdId}`);
    }
  };

  return (
    <div className="order-card mb-4">
      <div className="order-content">
        <Link
          style={{ textDecoration: 'none' }}
          className="d-flex align-items-center date mb-4"
        >
          <p style={{ color: 'red' }} onClick={() => showPup()}>
            how it works <AiOutlineQuestionCircle style={{ color: 'red' }} />
          </p>
        </Link>

        {productPrice >= 1200 && productPrice <= 4000 && (
          <ThreeMonthsPlanDisplay productPrice={productPrice} />
        )}

        <button className="orderbutton" onClick={() => checkUser()}>
          Choose
        </button>
      </div>
      {showpopup && (
        <OrderCradPopup setShowpopup={setShowpopup} showpopup={showpopup} />
      )}
      {showCreditpopup && (
        <StoreCreditAlertPopup
          setShowCreditpopup={setShowCreditpopup}
          showCreditpopup={showCreditpopup}
        />
      )}
    </div>
  );
};

export default OrderCradThree;
