/* eslint-disable array-callback-return */
import React from 'react';
import ProductCard from '../components/ProductCard';

const ProductListComponent = ({ ent, cat, parray }) => {
  return (
    <>
      <section className="home-wrapper pb-5">
        <div className="container-fluid pt-2 pe-3 ps-4">
          <div className="row home-wrapper-divv">
            <div className="col-12 section-header pt-2   d-flex justify-content-between">
              <h6 className="text-white">{cat}</h6>
            </div>
            <div className="container-fluid mb-5">
              <div className="row">
                {parray.map((idx) => {
                  if (ent[idx]?.category === cat) {
                    return (
                      <div className="col-3  gap-10  mb-5">
                        <ProductCard prd={ent[idx]} />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductListComponent;
