/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Meta from '../components/Meta';
import BreadCrumb from '../components/BreadCrumb';
import { useParams } from 'react-router-dom';
import { useGetProductsQuery } from '../features/products/productsApiSlice';
import SpinerComponent from '../components/SpinerComponent';
import OrderCard from '../components/OrderCard';
import OrderCradTwo from '../components/OrderCradTwo';
import OrderCradThree from '../components/OrderCradThree';
import OrderCradSix from '../components/OrderCradSix';
import { useUpdateUserStoreCreditMutation } from '../features/users/customersApiSlice';

const SingleProductPage = () => {
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const [showSpina, setShowSpina] = useState(false);
  const { id } = useParams();
  const [eligiblePrd, setEligiblePrd] = useState(true);
  const [showInstallmentPlan, setShowInstallmentPlan] = useState(true);

  const [updateUserStoreCredit] = useUpdateUserStoreCreditMutation();

  const { product } = useGetProductsQuery('productsList', {
    selectFromResult: ({ data }) => ({
      product: data?.entities[id],
    }),
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    async function updateCredit() {
      await updateUserStoreCredit();
    }
    updateCredit();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (product?.price > 500) {
      setEligiblePrd(false);
    }
    if (product?.price + product?.deliveryPrice < 400) {
      setShowInstallmentPlan(false);
    }
  }, [product]);

  return (
    <>
      <Meta title="Product Name" />
      <BreadCrumb title="Product Name" />
      <div className="container-fluid main-product-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-6 col-md-6 col-sm-6">
            <div className="main-product-image">
              <img
                src={`${product?.images[0]?.url}`}
                alt="watch"
                className="big-image img-fluid"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="main-product-details">
              <div className="border-bottom ">
                <h3 className="title">{product?.title}</h3>

                <p className="price">{product?.description}</p>
              </div>
              <div className="border-bottom py-3">
                <p className="price">GHS{product?.price}</p>
              </div>
              <div className="border-bottom py-3">
                <p className="price">
                  Seller Shop: {product?.vendor?.BusinessName}
                </p>
              </div>

              {product?.price > 400 && (
                <div className="other-product-images d-flex flex-column gap-10 my-2">
                  {eligiblePrd && <OrderCard prdId={id} />}
                </div>
              )}

              {product?.price >= 400 && product?.price <= 4000 && (
                <div className="other-product-images d-flex flex-column gap-10 my-2">
                  {/* <TwoMonthsPlanDisplay productPrice={product?.price} /> */}
                  {/* 
                {showInstallmentPlan && <OrderCradTwo prdId={id} />} */}
                  <OrderCradTwo prdId={id} productPrice={product?.price} />
                </div>
              )}
              {product?.price >= 1200 && product?.price <= 4000 && (
                <div className="other-product-images d-flex flex-column gap-10 my-2">
                  <OrderCradThree prdId={id} productPrice={product?.price} />
                </div>
              )}
              {product?.price > 4000 && (
                <div className="other-product-images d-flex flex-column gap-10 my-2">
                  <OrderCradSix prdId={id} productPrice={product?.price} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showSpina && <SpinerComponent />}
    </>
  );
};

export default SingleProductPage;
