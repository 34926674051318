import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import OrderPopup from './OrderPopup';
import StoreCreditAlertPopup from './StoreCreditAlertPopup';
import { useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from '../features/users/customersApiSlice';

const OrderCard = ({ prdId }) => {
  const navigate = useNavigate();
  const [showpopup, setShowpopup] = useState(false);
  const [showCreditpopup, setShowCreditpopup] = useState(false);
  const [deactivateBtn, setDeactivateBtn] = useState(false);
  const user = JSON.parse(localStorage.getItem('customer'));
  const userid = localStorage.getItem('cid');

  const { usa } = useGetUsersQuery('usersList', {
    selectFromResult: ({ data }) => ({
      usa: data?.entities[userid],
    }),
  });

  const checkUser = () => {
    if (user === null) {
      navigate('/login');
    } else if (usa?.storeCredit < 500) {
      setShowCreditpopup(true);
    } else {
      navigate(`/order-product/${prdId}`);
    }
  };

  useEffect(() => {
    const datey = new Date();
    const daty = datey.toDateString();

    let dayOfMonth = daty.split(' ');
    dayOfMonth.forEach((day) => {
      if (
        day === '24' ||
        day === '25' ||
        day === '26' ||
        day === '27' ||
        day === '28' ||
        day === '29' ||
        day === '30' ||
        day === '31'
      ) {
        setDeactivateBtn(true);
      }
    });
  }, []);

  const showPup = () => {
    setShowpopup(!showpopup);
  };
  //console.log(deactivateBtn);
  return (
    <div className="order-card mb-4">
      <div className="order-content">
        <Link
          style={{ textDecoration: 'none' }}
          className="d-flex align-items-center date mb-4"
        >
          <p style={{ color: 'red' }} onClick={() => showPup()}>
            how it works <AiOutlineQuestionCircle style={{ color: 'red' }} />
          </p>
        </Link>
        <h5 className="title">Pay On PayDay</h5>
        <p className="desc">
          Buy now, get your delivery and pay back when you get paid!
        </p>

        <button
          disabled={deactivateBtn === true ? true : false}
          className="orderbutton"
          onClick={() => checkUser()}
        >
          {deactivateBtn ? `unavailable` : 'choose'}
        </button>

        {deactivateBtn && (
          <p className="order-warning">
            This service is only available from 1st to 23rd of the month
          </p>
        )}
      </div>
      {showpopup && (
        <OrderPopup setShowpopup={setShowpopup} showpopup={showpopup} />
      )}
      {showCreditpopup && (
        <StoreCreditAlertPopup
          setShowCreditpopup={setShowCreditpopup}
          showCreditpopup={showCreditpopup}
        />
      )}
    </div>
  );
};

export default OrderCard;
