import React from 'react';

const SixMonthsPlan = ({ productPrice, onCh }) => {
  return (
    <div className="d-flex border-bottom flex-column gap-1">
      <div className="product-card">
        <div className="d-flex">
          <input
            type="radio"
            name="payplan"
            value="6 MONTHS"
            id="6 MONTHS"
            onChange={onCh}
            className="me-1 mb-0"
          />
          <label className="price-label">6 months</label>
        </div>
        <div>
          <p className="price-pln">
            Initial payment: GHS {Math.round(productPrice * 0.25)}
          </p>
          <p className="price-pln">
            Next payment: GHS {Math.round(productPrice * 0.15)}
          </p>
          <p className="price-pln">
            Next payment: GHS {Math.round(productPrice * 0.15)}
          </p>
          <p className="price-pln">
            Next payment: GHS {Math.round(productPrice * 0.15)}
          </p>
          <p className="price-pln">
            Next payment: GHS {Math.round(productPrice * 0.15)}
          </p>
          <p className="price-pln">
            Next payment: GHS {Math.round(productPrice * 0.15)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SixMonthsPlan;
