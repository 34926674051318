import React from 'react';

const SixMonthsPlanDisplay = ({ productPrice }) => {
  return (
    <div className="d-flex flex-column gap-1">
      <div className="product-card">
        <label className="price-label">6 months</label>
        <div>
          <p className="price-pln">
            Initial payment: GHS {Math.round(productPrice * 0.25)}
          </p>
          <p className="price-pln">
            Next payment: GHS {Math.round(productPrice * 0.15)}
          </p>
          <p className="price-pln">
            Next payment: GHS {Math.round(productPrice * 0.15)}
          </p>
          <p className="price-pln">
            Next payment: GHS {Math.round(productPrice * 0.15)}
          </p>
          <p className="price-pln">
            Next payment: GHS {Math.round(productPrice * 0.15)}
          </p>
          <p className="price-pln">
            Next payment: GHS {Math.round(productPrice * 0.15)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SixMonthsPlanDisplay;
