/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Select } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from '../features/users/customersApiSlice';
import { useGetProductsQuery } from '../features/products/productsApiSlice';
import { useGetAgentsQuery } from '../features/agents/agentsApiSlice';
import { useCreateOrderMutation } from '../features/users/customersApiSlice';
import CustomInputy from '../components/CustomInputTwo';
import { toast } from 'react-toastify';
import { customAlphabet } from 'nanoid';
import SpinerComponent from '../components/SpinerComponent';
import Meta from '../components/Meta';

let schema = Yup.object().shape({
  firstname: Yup.string().required('first name is required'),
  lastname: Yup.string().required('last name is required'),
  city: Yup.string().required('city is required'),
  phone: Yup.string().required('phone number is required'),
  address: Yup.string().required('address is required'),
});

const CheckOut = () => {
  const nanod = customAlphabet('1234567890', 6);
  const idz = nanod();
  const orderNum = `#${idz}`;
  const { id } = useParams();
  const userr = JSON.parse(localStorage.getItem('customer'));
  const userid = localStorage.getItem('cid');
  const umail = localStorage.getItem('cmail');
  const [showSpina, setShowSpina] = useState(false);
  const navigate = useNavigate();
  const [orderNot, setOrderNot] = useState(null);
  const [paymentDuration, setPaymentDuration] = useState('TODAY');

  let amail = null;
  let aname = null;
  let idx = null;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (userr === null) {
      navigate('/login');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { user } = useGetUsersQuery('usersList', {
    selectFromResult: ({ data }) => ({
      user: data?.entities[userid],
    }),
  });

  const { product } = useGetProductsQuery('productsList', {
    selectFromResult: ({ data }) => ({
      product: data?.entities[id],
    }),
  });

  const {
    data,
    isSuccess: agentSuc,
    isLoading: agentLoad,
    error: agentErr,
  } = useGetAgentsQuery('agentList', {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  if (agentSuc) {
    const { ids, entities } = data;

    idx = ids.find((idx) => entities[idx]?.company === user?.company);

    amail = entities[idx]?.email;
    aname = entities[idx]?.firstname;
  }

  const handlePayDuratn = (e) => {
    setPaymentDuration(e.target.value);
  };

  const [createOrder, { isSuccess, error, isLoading }] =
    useCreateOrderMutation();

  const localDate = new Date();

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  let todaysDate = localDate.getDate();
  let currentMonth = months[localDate.getMonth()];
  let currentYear = localDate.getFullYear();
  const nextPaymentDate = `28th ${currentMonth}, ${currentYear}`;
  const paymentPlan = `PAY ON PAYDAY`;
  const totalPrice = product?.price + product?.deliveryPrice;
  const deliveryPrice = product?.deliveryPrice;
  const orderDate = `${todaysDate} ${currentMonth}, ${currentYear}`;

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }
    if (isSuccess) {
      toast.success('Order created Successfully!');
      setShowSpina(false);
      sendEmailToAgent();
      sendEmailToUser();
      navigate('/');
    }

    if (error) {
      setShowSpina(false);

      toast.error('Something went wrong!');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isLoading, error]);

  const sendEmailToUser = async () => {
    try {
      const email = umail;
      const firstname = user?.firstname;
      const orderNo = orderNot;
      const formData = new FormData();
      formData.append('firstname', firstname);
      formData.append('email', email);
      formData.append('orderNo', orderNo);
      const urlEncoded = new URLSearchParams(formData).toString();
      await fetch(
        'https://worknshup-main-backend-a9a88924fa9a.herokuapp.com/api/user/send-email-order-created',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
          },
          body: urlEncoded,
        }
      ).catch((err) => console.log(`Error occured: ${err}`));
    } catch (err) {
      console.log('error: email not sent!');
    }
  };

  const sendEmailToAgent = async () => {
    try {
      const agentmail = amail;
      const firstname = user?.firstname;
      const agentname = aname;
      const orderNo = orderNot;
      const formData = new FormData();
      formData.append('firstname', firstname);
      formData.append('agentname', agentname);
      formData.append('agentmail', agentmail);
      formData.append('orderNo', orderNo);
      const urlEncoded = new URLSearchParams(formData).toString();
      await fetch(
        'https://worknshup-main-backend-a9a88924fa9a.herokuapp.com/api/user/send-email-order-agent',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
          },
          body: urlEncoded,
        }
      ).catch((err) => console.log(`Error occured: ${err}`));
    } catch (err) {
      console.log('error: email not sent!');
    }
  };

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      city: '',
      phone: '',
      address: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      setOrderNot(orderNum);
      const deliveryDetails = {
        firstname: values.firstname,
        lastname: values.lastname,
        city: values.city,
        phone: values.phone,
        address: values.address,
      };

      await createOrder({
        product: product?._id,
        deliveryDetails,
        quantity: 1,
        price: product?.price,
        totalPrice,
        nextPaymentDate,
        paymentPlan,
        deliveryPrice,
        orderNumber: orderNum,
        orderDate,
        paymentDuration,
      });
    },
  });

  return (
    <>
      <Meta title="CheckOut" />
      <div className="checkout-wrapper py-5 home-wrapper-2">
        <div className="container-fluid">
          <form action="" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-7 col-md-7 col-sm-7">
                <h4>Delivery details</h4>
                <div className="mb-2">
                  <div className=" gap-10 align-items-center order-flex">
                    <div>
                      <CustomInputy
                        type="text"
                        placeholder="Enter First Name"
                        name="firstname"
                        onCh={formik.handleChange('firstname')}
                        onBl={formik.handleBlur('firstname')}
                        val={formik.values.firstname}
                      />
                      <div className="error">
                        {formik.touched.firstname && formik.errors.firstname}
                      </div>
                    </div>
                    <div>
                      <CustomInputy
                        type="text"
                        placeholder="Enter Last Name"
                        name="lastname"
                        onCh={formik.handleChange('lastname')}
                        onBl={formik.handleBlur('lastname')}
                        val={formik.values.lastname}
                      />
                      <div className="error">
                        {formik.touched.lastname && formik.errors.lastname}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-50">
                  <CustomInputy
                    type="text"
                    placeholder="Phone Number"
                    name="phone"
                    onCh={formik.handleChange('phone')}
                    onBl={formik.handleBlur('phone')}
                    val={formik.values.phone}
                  />
                  <div className="error">
                    {formik.touched.phone && formik.errors.phone}
                  </div>
                </div>
                <div className="border border-secondary mt-2 w-50  px-2">
                  <div className="fs-6">Region:</div>
                  <div className="fs-6">Greater Accra</div>
                </div>
                <div>
                  <Select
                    className="select-cat"
                    showSearch
                    placeholder="Select City"
                    optionFilterProp="children"
                    onChange={formik.handleChange('city')}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: 'Abeka',
                        label: 'Abeka',
                      },
                      {
                        value: 'Abelemkpe',
                        label: 'Abelemkpe',
                      },
                      {
                        value: 'Abokobi',
                        label: 'Abokobi',
                      },
                      {
                        value: 'Abossey Okai',
                        label: 'Abossey Okai',
                      },
                      {
                        value: 'Accra Mall',
                        label: 'Accra Mall',
                      },
                      {
                        value: 'Accra NewTown',
                        label: 'Accra NewTown',
                      },
                      {
                        value: 'Achimota',
                        label: 'Achimota',
                      },
                      {
                        value: 'Adabraka',
                        label: 'Adabraka',
                      },
                      {
                        value: 'Adenta',
                        label: 'Adenta',
                      },
                      {
                        value: 'Afariwaa',
                        label: 'Afariwaa',
                      },
                      {
                        value: 'Afienya',
                        label: 'Afienya',
                      },
                      {
                        value: 'Agbogba',
                        label: 'Agbogba',
                      },
                      {
                        value: 'Agbogloshie',
                        label: 'Agbogloshie',
                      },
                      {
                        value: 'Airport',
                        label: 'Airport',
                      },
                      {
                        value: 'Amasaman',
                        label: 'Amasaman',
                      },
                      {
                        value: 'Ashiaman',
                        label: 'Ashiaman',
                      },
                      {
                        value: 'Baatsona-Spintex',
                        label: 'Baatsona-Spintex',
                      },
                      {
                        value: 'Bortianor',
                        label: 'Bortianor',
                      },
                      {
                        value: 'Bubuashie',
                        label: 'Bubuashie',
                      },
                      {
                        value: 'Cantonment',
                        label: 'Cantonment',
                      },
                      {
                        value: 'Caprice',
                        label: 'Caprice',
                      },
                      {
                        value: 'Chorkor',
                        label: 'Chorkor',
                      },
                      {
                        value: 'Circle',
                        label: 'Circle',
                      },
                      {
                        value: 'Dansoman',
                        label: 'Dansoman',
                      },
                      {
                        value: 'Darkuman',
                        label: 'Darkuman',
                      },
                      {
                        value: 'Dawhenya',
                        label: 'Dawhenya',
                      },
                      {
                        value: 'Dodowa',
                        label: 'Dodowa',
                      },
                      {
                        value: 'Dome',
                        label: 'Dome',
                      },
                      {
                        value: 'Dzorwulu',
                        label: 'Dzorwulu',
                      },
                      {
                        value: 'East Legon',
                        label: 'East Legon',
                      },
                      {
                        value: 'East Legon Hills',
                        label: 'East Legon Hills',
                      },
                      {
                        value: 'Gbawe',
                        label: 'Gbawe',
                      },
                      {
                        value: 'Gbestile',
                        label: 'Gbestile',
                      },
                      {
                        value: 'Gbestele',
                        label: 'Gbestele',
                      },
                      {
                        value: 'Haatso',
                        label: 'Haatso',
                      },
                      {
                        value: 'James Town',
                        label: 'James Town',
                      },
                      {
                        value: 'Junction Mall',
                        label: 'Junction Mall',
                      },
                      {
                        value: 'Kanda',
                        label: 'Kanda',
                      },
                      {
                        value: 'Kaneshie',
                        label: 'Kaneshie',
                      },
                      {
                        value: 'Kissieman',
                        label: 'Kissieman',
                      },
                      {
                        value: 'Kokrobite',
                        label: 'Kokrobite',
                      },
                      {
                        value: 'Korle Bu',
                        label: 'Korle Bu',
                      },
                      {
                        value: 'Kpone',
                        label: 'Kpone',
                      },
                      {
                        value: 'Kisabenya',
                        label: 'Kisabenya',
                      },
                      {
                        value: 'Kwashieman',
                        label: 'Kwashieman',
                      },
                      {
                        value: 'La Bawaleshi',
                        label: 'La Bawaleshi',
                      },
                      {
                        value: 'Labadi',
                        label: 'Labadi',
                      },
                      {
                        value: 'Labone',
                        label: 'Labone',
                      },
                      {
                        value: 'Lakeside Estate',
                        label: 'Lakeside Estate',
                      },
                      {
                        value: 'Lapaz',
                        label: 'Lapaz',
                      },
                      {
                        value: 'Lartebiokorshie',
                        label: 'Lartebiokorshie',
                      },
                      {
                        value: 'Lashibi',
                        label: 'Lashibi',
                      },
                      {
                        value: 'Legon',
                        label: 'Legon',
                      },
                      {
                        value: 'Madina',
                        label: 'Madina',
                      },
                      {
                        value: 'Makola',
                        label: 'Makola',
                      },
                      {
                        value: 'Mallam',
                        label: 'Mallam',
                      },
                      {
                        value: 'Mamobi',
                        label: 'Mamobi',
                      },
                      {
                        value: 'Mamprobi',
                        label: 'Mamprobi',
                      },
                      {
                        value: 'McCarthy Hill',
                        label: 'McCarthy Hill',
                      },
                      {
                        value: 'Mile 7',
                        label: 'Mile 7',
                      },
                      {
                        value: 'Ministries',
                        label: 'Ministries',
                      },
                      {
                        value: 'Nima',
                        label: 'Nima',
                      },
                      {
                        value: 'North.Ridge',
                        label: 'North.Ridge',
                      },
                      {
                        value: 'Nungua',
                        label: 'Nungua',
                      },
                      {
                        value: 'Oblogo Mallam',
                        label: 'Oblogo Mallam',
                      },
                      {
                        value: 'Odorkor',
                        label: 'Odorkor',
                      },
                      {
                        value: 'Old Ashongman',
                        label: 'Old Ashongman',
                      },
                      {
                        value: 'Osu',
                        label: 'Osu',
                      },
                      {
                        value: 'Oyarifa',
                        label: 'Oyarifa',
                      },
                      {
                        value: 'Oyibi',
                        label: 'Oyibi',
                      },
                      {
                        value: 'Pantang',
                        label: 'Pantang',
                      },
                      {
                        value: 'Prampram',
                        label: 'Prampram',
                      },
                      {
                        value: 'Ridge',
                        label: 'Ridge',
                      },
                      {
                        value: 'Roman Ridge',
                        label: 'Roman Ridge',
                      },
                      {
                        value: 'Sakumono',
                        label: 'Sakumono',
                      },
                      {
                        value: 'Santa Maria',
                        label: 'Santa Maria',
                      },
                      {
                        value: 'Sowutuom',
                        label: 'Sowutuom',
                      },
                      {
                        value: 'Spintex Flower Pot',
                        label: 'Spintex Flower Pot',
                      },
                      {
                        value: 'Spintex GT Bank',
                        label: 'Spintex GT Bank',
                      },
                      {
                        value: 'Taifa',
                        label: 'Taifa',
                      },
                      {
                        value: 'Teiman',
                        label: 'Teiman',
                      },
                      {
                        value: 'Tema Community 9',
                        label: 'Tema Community 9',
                      },
                      {
                        value: 'Tema Community 1',
                        label: 'Tema Community 1',
                      },
                      {
                        value: 'Tema Community 10',
                        label: 'Tema Community 10',
                      },
                      {
                        value: 'Tema Community 11',
                        label: 'Tema Community 11',
                      },
                      {
                        value: 'Tema Community 12',
                        label: 'Tema Community 12',
                      },
                      {
                        value: 'Tema Community 18',
                        label: 'Tema Community 18',
                      },
                      {
                        value: 'Tema Community 2',
                        label: 'Tema Community 2',
                      },
                      {
                        value: 'Tema Community 22',
                        label: 'Tema Community 22',
                      },
                      {
                        value: 'Tema Community 25',
                        label: 'Tema Community 25',
                      },
                      {
                        value: 'Tema Community 3',
                        label: 'Tema Community 3',
                      },
                      {
                        value: 'Tema Community 4',
                        label: 'Tema Community 4',
                      },
                      {
                        value: 'Tema Community 5',
                        label: 'Tema Community 5',
                      },
                      {
                        value: 'Tema Community 6',
                        label: 'Tema Community 6',
                      },
                      {
                        value: 'Tema Community 7',
                        label: 'Tema Community 7',
                      },
                      {
                        value: 'Tema Community 8',
                        label: 'Tema Community 8',
                      },
                      {
                        value: 'Tema New Town',
                        label: 'Tema New Town',
                      },
                      {
                        value: 'Tesano',
                        label: 'Tesano',
                      },
                      {
                        value: 'Teshie',
                        label: 'Teshie',
                      },
                      {
                        value: 'Tetegu',
                        label: 'Tetegu',
                      },
                      {
                        value: 'Tse Addo',
                        label: 'Tse Addo',
                      },
                      {
                        value: 'Tuba',
                        label: 'Tuba',
                      },
                      {
                        value: 'Tudu',
                        label: 'Tudu',
                      },
                      {
                        value: 'Weiga',
                        label: 'Weiga',
                      },
                      {
                        value: 'West Hills',
                        label: 'West Hills',
                      },
                    ]}
                  />
                  <div className="error">
                    {formik.touched.city && formik.errors.city}
                  </div>
                </div>
                <div className="w-50 mt-2 ">
                  <CustomInputy
                    type="text"
                    placeholder="Address"
                    name="address"
                    onCh={formik.handleChange('address')}
                    onBl={formik.handleBlur('address')}
                    val={formik.values.address}
                    className="py-3"
                  />
                  <div className="error">
                    {formik.touched.address && formik.errors.address}
                  </div>
                </div>
              </div>
              <div className="col-5 col-md-5 col-sm-5">
                <div className="border-bottom py-4 gap-10">
                  <h4>Order details</h4>
                  <div className="d-flex gap-10 align-items-center  justify-content-between ">
                    <div className="w-75 d-flex gap-10">
                      <div className="w-25 position-relative">
                        <img
                          className="img-fluid"
                          src={`${product?.images[0]?.url}`}
                          alt="watch"
                        />
                      </div>
                      <div>
                        <h5 className="total-price">{product?.title}</h5>
                        {/* <p className="total-price">ggvnhg</p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-bottom py-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="total">Item price</p>
                    <p className="total-price">GHS {product?.price}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0 total">Delivery fees</p>
                    <p className="mb-0 total-price">
                      GHS {product?.deliveryPrice}
                    </p>
                  </div>
                </div>
                <div className="d-flex border-bottom justify-content-between align-items-center">
                  <h4 className="total">Total</h4>
                  <h5 className="total-price">
                    GHS {product?.price + product?.deliveryPrice}
                  </h5>
                </div>
                <div className="d-flex my-5 border-bottom justify-content-between align-items-center">
                  <h4 className="total">Payment Plan</h4>
                  <h5 className="total-price">Pay on Payday</h5>
                </div>
                <div className="d-flex my-5 border-bottom justify-content-between align-items-center">
                  <h4 className="total">Payment due date</h4>
                  <h5 className="total-price">{nextPaymentDate}</h5>
                </div>
                <button
                  className="orderbuttonT w-100 border-0 mt-4"
                  type="submit"
                >
                  Confirm Order
                </button>
              </div>
            </div>
            {showSpina && <SpinerComponent />}
          </form>
        </div>
      </div>
    </>
  );
};

export default CheckOut;
