import React from 'react';
import { Link } from 'react-router-dom';

const ProductCard = ({ prd }) => {
  return (
    <div className="container-fluid">
      <Link to={`${`/product/${prd?._id}`}`} style={{ textDecoration: 'none' }}>
        <div className="blog-card mb-4 mt-4">
          <div className="blog-img">
            <img
              src={prd?.images[0]?.url}
              className="img-fluid"
              alt="blogimage"
            />
          </div>
          <div className="blog-content">
            <h5 className="title">{prd?.title}</h5>
            <p className="date">GHC {prd?.price}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
