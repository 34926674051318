import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Login from './pages/Login';
import PersistLogin from './features/auth/PersistLogin';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import SignupOne from './pages/SignupOne';
import SignupPageTwo from './pages/SignupPageTwo';
import SignupPageThree from './pages/SignupPageThree';
import SingleProductPage from './pages/SingleProductPage';
import ProductSeeAllPage from './pages/ProductSeeAllPage';
import ChexkOutT from './components/ChexkOutT';
import CheckOut from './components/CheckOut';
import UserAccountDetails from './pages/UserAccountDetails';
import UserOrders from './pages/UserOrders';
import OrderDetailPage from './pages/OrderDetailPage';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="signup-one" element={<SignupOne />} />
            <Route path="signup-page-two/:id" element={<SignupPageTwo />} />
            <Route path="signup-page-three/:id" element={<SignupPageThree />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />
            <Route path="/product/:id" element={<SingleProductPage />} />
            <Route path="/see-all-products" element={<ProductSeeAllPage />} />
            <Route element={<PersistLogin />}>
              <Route
                path="/user-order-details/:id"
                element={<OrderDetailPage />}
              />
              <Route
                path="/order-product-installment/:id"
                element={<ChexkOutT />}
              />
              <Route path="/order-product/:id" element={<CheckOut />} />
              <Route
                path="/user-account-page"
                element={<UserAccountDetails />}
              />
              <Route path="/user-orders" element={<UserOrders />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
