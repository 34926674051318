import React from 'react';
import { Link } from 'react-router-dom';

const StoreCreditAlertPopup = ({ showCreditpopup, setShowCreditpopup }) => {
  return (
    <div className="popup-main">
      <div className="popup">
        <div className="popup-header">
          <h1>Credit alert</h1>
          <Link>
            <h1 onClick={() => setShowCreditpopup((showCreditpopup = false))}>
              X
            </h1>
          </Link>
        </div>
        <div>
          <p>You have insufficient funds in your store to order this product</p>
        </div>
      </div>
    </div>
  );
};

export default StoreCreditAlertPopup;
