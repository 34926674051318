import React from 'react';
//import { BsLinkedin, BsYoutube, BsInstagram, BsGithub } from 'react-icons/bs';

const Footer = () => {
  return (
    <>
      <footer className="py-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h4 className="text-center text-white mb-4">Contact Us</h4>
              <div className="text-center">
                <p
                  href="mailto:  worknshup@gmail.com"
                  className="mt-1 d-block mb-2 text-white"
                >
                  worknshup@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="py-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <p className="text-center mb-0 text-white">
                &copy; {new Date().getFullYear()}, powered by worknshup
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
