import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';

const ProductsAdapter = createEntityAdapter({});

const initialState = ProductsAdapter.getInitialState();

export const productsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: () => ({
        url: '/products/all-products',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedProducts = responseData.map((product) => {
          product.id = product._id;
          return product;
        });
        return ProductsAdapter.setAll(initialState, loadedProducts);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: 'Products', id: 'LIST' },
            ...result.ids.map((id) => ({ type: 'Products', id })),
          ];
        } else return [{ type: 'Products', id: 'LIST' }];
      },
    }),
    addNewProduct: builder.mutation({
      query: (initialProductData) => ({
        url: '/Products',
        method: 'POST',
        body: {
          ...initialProductData,
        },
      }),
      invalidatesTags: [{ type: 'Product', id: 'LIST' }],
    }),
    updateProduct: builder.mutation({
      query: (initialProductData) => ({
        url: '/Products',
        method: 'PATCH',
        body: {
          ...initialProductData,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Product', id: arg.id },
      ],
    }),
    addToWishList: builder.mutation({
      query: (id) => ({
        url: `/Products/wishlist/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Product', id: arg.id },
      ],
    }),
    deleteProduct: builder.mutation({
      query: ({ id }) => ({
        url: `/Products`,
        method: 'DELETE',
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Product', id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useAddNewProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useAddToWishListMutation,
} = productsApiSlice;

// returns the query result object
export const selectProductsResult =
  productsApiSlice.endpoints.getProducts.select();

// creates memoized selector
const selectProductsData = createSelector(
  selectProductsResult,
  (ProductsResult) => ProductsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllProducts,
  selectById: selectProductById,
  selectIds: selectProductIds,
  // Pass in a selector that returns the Products slice of state
} = ProductsAdapter.getSelectors(
  (state) => selectProductsData(state) ?? initialState
);
