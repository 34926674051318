/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useGetProductsQuery } from '../features/products/productsApiSlice';
import ProductShowAll from '../components/ProductShowAll';
import { useLocation } from 'react-router-dom';
import Meta from '../components/Meta';
import BreadCrumb from '../components/BreadCrumb';

const ProductSeeAllPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('category');

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  let categori;
  let parray = [];

  const { data, isSuccess } = useGetProductsQuery('productsList', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  if (isSuccess) {
    const { ids, entities } = data;
    parray = ids.filter((idxx) => {
      return entities[idxx]?.category === query;
    });

    categori = <ProductShowAll parray={parray} cat={query} ent={entities} />;
  }
  return (
    <>
      <Meta title={`${query}`} />
      <BreadCrumb title={`${query}`} />
      <section className="home-wrapper ">
        <div className="container-fluid py-1 mx-1">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div
                id="carouselExampleCaptions"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src="/images/carouselOne.png"
                      className="d-block w-100 image-cara"
                      alt="carousel"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/images/carouselTwo.png"
                      className="d-block w-100 image-cara"
                      alt="carousel"
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {categori}
    </>
  );
};

export default ProductSeeAllPage;
