import React from 'react';
import { Link } from 'react-router-dom';

const OrderCradPopup = ({ showpopup, setShowpopup }) => {
  return (
    <div className="popup-main">
      <div className="popup">
        <div className="popup-header">
          <h1>Buy Now, Pay with Month End Salary!</h1>
          <Link style={{ textDecoration: 'none' }}>
            <h1 onClick={() => setShowpopup((showpopup = false))}>X</h1>
          </Link>
        </div>
        <div>
          <p style={{ fontWeight: 'bold' }}>
            Buy now, pay Installmentally every month end when your salary
            arrives.
            <br /> <br />
            <span style={{ color: 'red' }}>
              Please note failure to pay will attract a 12.5% fine and may
              involve Your HR and the Police.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderCradPopup;
