/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

const TwoMonthsPlanDisplay = ({ productPrice }) => {
  return (
    <div className="d-flex flex-column gap-1">
      <div className="product-card">
        <label className="price-label">2 months</label>
        <div>
          <p className="price-pln">
            Initial payment: GHS {Math.round(productPrice * 0.55)}
          </p>
          <p className="price-pln">
            Next payment: GHS {Math.round(productPrice * 0.45)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TwoMonthsPlanDisplay;
